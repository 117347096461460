<template>
	<div class="assessment-edit">
		<div class="header">
			<h4 class="title">整车配置</h4>
			<div class="content">
				<div class="item w33"><span>VIN：</span>{{quotation.vin}}</div>
				<div class="item w33"><span>型号：</span>{{getModel(pinfo)}}</div>
				<div class="item w33"><span>颜色：</span>{{getColor(pinfo)}}</div>
				<div class="item w100">
					<span>其他信息：</span>{{getOtherInfo(pinfo)}}
				</div>
			</div>
		</div>
		<el-table :data="tableData" class="main-table" header-row-class-name="table-header" cell-class-name="table-hover"
			:row-class-name="tableRowClassName">
			<el-table-column label="序号" type="index" :index="indexMethod" align="center">
			</el-table-column>
			<!-- <el-table-column label="零件ID" prop="partId" align="center">
            </el-table-column> -->
			<el-table-column label="零件号" prop="productCode">
			</el-table-column>
			<el-table-column label="零件名称" prop="productName" width="150">
			</el-table-column>
			<el-table-column label="维修类型" prop="repairType" align="center" width="80">
			</el-table-column>
			<el-table-column label="修理厂报价" prop="repairPrice" align="center" width="100">
			</el-table-column>
			<el-table-column label="数量" prop="qty" align="center" width="50">
			</el-table-column>
			<el-table-column label="备注" prop="remark" align="center" width="50">
			</el-table-column>
			<el-table-column label="匹配号" width="150">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reProductCode" placeholder="请输入匹配号" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="匹配名称" width="150">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reProductName" placeholder="请输入匹配名称" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="整车用量" width="80">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reVehicleQty" placeholder="请输入整车用量" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="4S店价格" width="90">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reShopPrice" placeholder="请输入4S店价格" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="赋界价格" width="80">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reFujiePrice" placeholder="请输入赋界价格" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="原厂供货价" width="90">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reFactoryPrice" placeholder="请输入原厂供货价" size="mini"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="品牌供货价" width="90">
				<template slot-scope="scope">
					<el-input v-model="scope.row.reBrandPrice" placeholder="请输入品牌供货价" size="mini"></el-input>
				</template>
			</el-table-column>
            <el-table-column label="备注" width="90">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.reRemark" placeholder="请输入备注" size="mini"></el-input>
                </template>
            </el-table-column>
			<el-table-column label="操作" width="150" align="center" class-name="table-operate">
				<template slot-scope="{row}">
					<el-button class="operation-btn" size="small" type="primary" @click="editClick(row)">匹配</el-button>
					<el-button v-if="engInfo.engCode" class="operation-btn" size="small" type="primary" @click="wcEditClick(row)">潍柴匹配</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleCancel">取消</el-button>
			<el-button type="primary" @click="previewClick">保存并预览</el-button>
			<el-button type="primary" @click="sendClick">保存并回传</el-button>
			<el-button type="primary" @click="handleSubmit">保存</el-button>
		</div>
		<!-- 匹配 -->
		<el-dialog title="匹配" :visible.sync="mateDialog" width="70%" custom-class="main-dialog edit-dialog"
			:append-to-body="true" top='10vh'>
			<assessmentMate :pinfo="pinfo" :vinInfo="vinInfo"
                            :currentRow="currentRow" :condidateList="condidateList" @mateCancel="mateCancel"
				@mateSubmit="mateSubmit"></assessmentMate>
		</el-dialog>
		<!-- 潍柴匹配 -->
		<el-dialog title="潍柴匹配" :visible.sync="wcMateDialog" width="70%" custom-class="main-dialog edit-dialog"
			:append-to-body="true" top='10vh'>
			<assessmentWcMate :engInfo="engInfo" :currentRow="currentRow" :condidateList="condidateList" @mateCancel="WcMateCancel"
				@mateSubmit="WcMateSubmit"></assessmentWcMate>
		</el-dialog>
	</div>
</template>

<script>
	import assessmentMate from "./assessmentMate.vue";
	import assessmentWcMate from "./assessmentWcMate.vue";
	import {
		getDayCountOfMonth
	} from "element-ui/src/utils/date-util";
	import {
		fetchCondidateList,
		updateItems,
		getNowFormatStr,
		downFile,
		send
	} from "../../api/insuranceQuote";
	import {
		calcBrandPrice,
		calcFujiePrice,
		calcSubFactoryPrice,
		calcSupplierPrice,
		getPrice,
		getServicePrice
	} from "../../common/calcPrice";

	export default {
		components: {
			assessmentMate,
			assessmentWcMate
		},
		props: {
			quotation: {
				type: Object,
				default: () => {
					return {}
				}
			},
			pinfo: {
				type: Object,
				default: () => {
					return {}
				}
			},
            vinInfo: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            engInfo: {
                type: Object,
                default: () => {
                    return {}
                }
            }
		},
		watch: {
			quotation: {
				handler: function(val) {
					let list = JSON.parse(JSON.stringify(val.quotationItemList))
					if (!list) {
						list = []
					}
					for (let i = 0; i < list.length; i++) {
						let item = list[i]
						if (item.rePrice && item.init == 0 && !item.reShopPrice && !item.reFujiePrice &&
							!item.reFactoryPrice && !item.reBrandPrice) {
							list[i] = this.fillPrice(item)
						}
					}
					this.tableData = list
				},
				deep: true,
				immediate: true,
            },
		},
		data() {
			return {
				wcMateDialog: false,
				mateDialog: false,
				tableData: [{}, {}, {}],
				query: {
					keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: '',
				condidateList: [],
				currentRow: {},
			}
		},
		methods: {
			getDayCountOfMonth,
			handleCancel() {
				this.$emit('editCancel')
			},
			handleSubmit() {
				return new Promise((resolve, reject) => {
					let quotation = JSON.parse(JSON.stringify(this.quotation))
					quotation.quotationItemList = this.tableData
					updateItems(quotation).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '保存成功！',
								type: 'success'
							});
							this.$emit('editSubmit')
							resolve()
						} else {
							reject()
						}
					})
				})
			},
			mateSubmit(val) {
				if (!val||JSON.stringify(val)==="{}") {
					this.$message({
						message: '请点击表格选择零件！',
						type: 'warning'
					});
				} else {
					this.currentRow.reProductId = val.productId;
					this.currentRow.reProductCode = val.productCode
					this.currentRow.reProductName = val.productName
					this.currentRow.rePrice = parseFloat(val.price) ? parseFloat(val.price) : ''
					this.currentRow.reShopPrice = parseFloat(val.shopPrice) ? parseFloat(val.shopPrice) : ''
					this.currentRow.reFujiePrice = parseFloat(val.fujiePrice) ? parseFloat(val.fujiePrice) : ''
					this.currentRow.reFactoryPrice = parseFloat(val.factoryPrice) ? parseFloat(val.factoryPrice) : ''
					this.currentRow.reBrandPrice = parseFloat(val.brandPrice) ? parseFloat(val.brandPrice) : ''
					this.mateDialog = false
				}
			},
			mateCancel() {
				this.mateDialog = false
			},
			WcMateSubmit(val) {
				console.log('val',val)
				if (!val||JSON.stringify(val)==="{}") {
					this.$message({
						message: '请点击表格选择零件！',
						type: 'warning'
					});
				} else {
                    this.currentRow.reProductId = val.id;
                    this.currentRow.reProductCode = val.partCode
                    this.currentRow.reProductName = val.partName
					this.currentRow.reShopPrice = parseFloat(val.price) ? parseFloat(val.price) : ''
					this.wcMateDialog = false
				}
			},
			WcMateCancel() {
				console.log(1111)
				this.wcMateDialog = false
			},
			wcEditClick(row) {
				this.currentRow = row
				this.getCondidate(row)
				this.wcMateDialog = true
			},
			editClick(row) {
				this.currentRow = row
				this.getCondidate(row)
				this.mateDialog = true
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getModel(pinfo) {
				if (pinfo == null) {
					return ''
				} else {
					return pinfo.pdtSeriesText
				}
			},
			getColor(pinfo) {
				if (pinfo == null) {
					return ''
				} else {
					return pinfo.colorText
				}
			},
			getOtherInfo(pinfo) {
				if (pinfo == null) {
					return ''
				} else {
					return pinfo.config ? pinfo.config : ''
				}
			},
			getCondidate(row) {
				fetchCondidateList({
					itemId: row.id
				}).then(res => {
					if (res.code == 0) {
						this.condidateList = res.data
					}
				})
			},
			fillPrice(row) {
				row.priceEndUser = row.rePrice
				row.buyCode = row.reProductCode
				console.log('price', row, this.pinfo)
				row.reShopPrice = this.calcPrice(row)
				if (!parseFloat(row.reShopPrice)) {
					row.reShopPrice = ''
				}
				row.reFujiePrice = this.calcFujiePrice(row)
				if (!parseFloat(row.reFujiePrice)) {
					row.reFujiePrice = ''
				}
				row.reFactoryPrice = this.calcSupplierPrice(row)
				if (!parseFloat(row.reFactoryPrice)) {
					row.reFactoryPrice = ''
				}
				row.reBrandPrice = this.calcBrandPrice(row)
				if (!parseFloat(row.reBrandPrice)) {
					row.reBrandPrice = ''
				}
				return row
			},
			calcPrice(row) {
				return getPrice(row)
			},
			calcPriceService(row) {
				return getServicePrice(row, this.pinfo)
			},
			calcSupplierPrice(row) {
				return calcSupplierPrice(row, this.pinfo)
			},
			calcBrandPrice(row) {
				return calcBrandPrice(row, this.pinfo)
			},
			calcSubFactoryPrice(row) {
				return calcSubFactoryPrice(row, this.pinfo)
			},
			calcFujiePrice(row) {
				return calcFujiePrice(row, this.pinfo, this.vinInfo)
			},
			previewClick() {
				Promise.all([this.handleSubmit()]).then(() => {
					downFile('/api/admin/insuranceQuote/preview', {
						quotationId: this.quotation.id
					}).then((data) => {
						console.log('data', data)
						data = data.data
						if (!data) {
							this.$message.warning("文件下载失败")
							return
						}
                        let vin = this.quotation.vin;
                        if(vin.length>8){
                            vin = vin.substring(vin.length-8,vin.length)
                        }
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(new Blob([data], {
								type: 'application/vnd.ms-excel'
							}), '回传预览-' + vin + '.xlsx')
						} else {
							let url = window.URL.createObjectURL(new Blob([data], {
								type: 'application/vnd.ms-excel'
							}))
							let link = document.createElement('a')
							link.style.display = 'none'
							link.href = url
							link.setAttribute('download', '回传预览-' + vin + '-' + getNowFormatStr() + '.xlsx')
							document.body.appendChild(link)
							link.click()
							document.body.removeChild(link); //下载完成移除元素
							window.URL.revokeObjectURL(url); //释放掉blob对象
						}
					})
				})
			},
			sendClick() {
				//确认是否回传
				this.$confirm('确认回传吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					Promise.all([this.handleSubmit()]).then(() => {
						send({
							id: this.quotation.id
						}).then(() => {
							this.$message.success('发送成功')
							this.$emit('editSubmit')
						})
					})
				})
			},
		}
	}
</script>
<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
<style lang="scss" scoped>
	.assessment-edit {
		color: #FFF;

		.header {
			h4 {
				margin: 0 0 10px 0;
			}

			.content {
				display: flex;
				flex-wrap: wrap;

				.w33 {
					width: 33.3%;
				}

				.w100 {
					width: 100%;
				}

				.item {
					margin: 0 0 10px 0;

					span {
						display: inline-block;
						margin: 0 10px 0 0;
						border: 1px solid rgba($color: $main-color, $alpha: 0.5);
						padding: 2px 5px;
						background: rgba($color: $main-color, $alpha: 0.2);
						border-radius: 3px;
					}
				}
			}
		}

		.dialog-footer {
			text-align: right;
			margin: 20px 0 0 0;
		}
	}
</style>
