<template>
	<div class="assessment-edit">
		<div class="header">
			<div class="content">
				<div class="item w25"><span>零件号：</span>{{currentRow.productCode}}</div>
				<div class="item w25"><span>零件号名称：</span>{{currentRow.productName}}</div>
        <div class="item w25"><span>修理厂报价：</span>{{currentRow.repairPrice}}</div>
				<div class="item w25">
					<el-input placeholder="请输入零件号" v-model="query.keyword" size="small">
						<template slot="append">
							<el-button type="primary" class="search-btn" @click="searchClick"><i class="el-icon-search"></i>搜索</el-button>
						</template>
					</el-input>
				</div>
			</div>
		</div>
		<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
                  cell-class-name="table-hover" highlight-current-row
                  @current-change="handleCurrentChange"
                  default-expand-all :tree-props="{children: 'children'}"
        >
			<el-table-column label="序号" type="index" :index="indexMethod" width="50" align="center">
			</el-table-column>
			<el-table-column label="零件号" prop="productCode" align="center">
                <template slot-scope="{row}">
                    {{ row.replacePartNo ? row.replacePartNo : row.productCode }}
                    <el-tag
                        v-if="row.disuse && row.disuse == 1"
                        type="danger"
                        size="mini"
                        effect="dark">
                        停采
                    </el-tag>
                    <el-tooltip content="与原车颜色匹配" placement="top" v-if="pinfo && pinfo.productCode && row.productCode.substr(-3, 1) == '-'
                        && row.productCode.substr(-2, 2) == pinfo.productCode.substr(-2, 2)">
                        <el-button type="primary" icon="el-icon-connection" circle  size="mini" class="color-btn"></el-button>
                    </el-tooltip>
                </template>
			</el-table-column>
			<el-table-column label="名称" prop="productName" align="center">
			</el-table-column>
			<el-table-column label="4S价格" prop="shopPrice" align="center" width="110">
                <template scope="{row}">
                    <span v-if="calcPrice(row)">{{ calcPrice(row) }}</span>
                </template>
			</el-table-column>
            <el-table-column label="赋界价格" prop="fujiePrice" align="center" width="110">
                <template scope="{row}">
                    <span v-if="calcFujiePrice(row)">{{ calcFujiePrice(row) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="市场原厂价" prop="factoryPrice" align="center" width="110">
                <template scope="{row}">
                    <span v-if="calcSupplierPrice(row)">{{ calcSupplierPrice(row) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="市场品牌价" prop="brandPrice" align="center" width="110">
                <template scope="{row}">
                    <span v-if="calcBrandPrice(row)">{{ calcBrandPrice(row) }}</span>
                </template>
            </el-table-column>
		</el-table>
        <el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="query.page"
                       :page-size="query.pagesize" @current-change="onPageChange">
        </el-pagination>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleCancel">取消</el-button>
			<el-button type="primary" @click="handleSubmit">提交</el-button>
		</div>
	</div>
</template>

<script>
    import { search } from '@/api/insuranceQuote.js'
    import {
        calcBrandPrice,
        calcSubFactoryPrice,
        calcSupplierPrice,
        getPrice,
        getServicePrice,
        calcFujiePrice
    } from "../../common/calcPrice";
    export default {
        computed: {
            search() {
                return search
            }
        },
        props: ['pinfo', 'vinInfo', 'currentRow', 'condidateList'],
        watch: {
            condidateList: {
                handler: function (val) {
                    let list = JSON.parse(JSON.stringify(val))
                    for(let i=0; i<list.length; i++) {
                        list[i].buyCode = list[i].productCode
                        list[i].priceEndUser = list[i].price
                        list[i] = this.fillPrice(list[i])
                    }
                    this.tableData = list
                    this.total = list.length
                    this.query.page = 1
                    this.query.keyword = ''
                    console.log('table', this.tableData)
                },
                deep: true,
                immediate: true
            },
            pinfo: {
                handler: function (val) {
                    this.query.pinfoId = val.id
                },
                deep: true,
                immediate: true
            }
        },
		data() {
			return {
				searchInput: '',
				tableData: [],
				query: {
					keyword: '',
                    categoryId: '',
                    pinfoId: '',
					page: 1,
					pagesize: 10,
                    sort: '-price',
                    data: 'labor,insurePrice',
                    isCommon: 1
				},
				total: '',
                selectedRow: {}
			}
		},
		methods: {
			handleCancel(){
				this.$emit('mateCancel')
			},
			handleSubmit(){
				this.$emit('mateSubmit',this.selectedRow)
			},
			handleCurrentChange(val) {
				this.selectedRow = val;
			},
			editClick(row) {
				console.log(row)
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
            searchClick(){
                this.query.page = 1
                this.doSearch()
            },
            doSearch() {
                search(this.query).then(res => {
                    let list = res.data.content
                    list.forEach(e => {
                        e.productCode = e.buyCode
                        e.productName = e.name
                        e.productId = e.id
                        e.price = e.priceEndUser
                        e = this.fillPrice(e)
                        if (e.relatives && e.relatives.length > 0) {
                            e.children = e.relatives
                            e.children.forEach(c => {
                                c.id = c.id + '-' + c.id
                                c.priceEndUser = c.price
                                c = this.fillPrice(c)
                            })
                        }
                    })
                    this.tableData = list
                    this.total = res.data.totalElements
                })
            },
            fillPrice(row) {
                row.shopPrice = this.calcPrice(row)
                row.fujiePrice = this.calcFujiePrice(row)
                row.factoryPrice = this.calcSupplierPrice(row)
                row.brandPrice = this.calcBrandPrice(row)
                return row
            },
            calcPrice(row) {
                return getPrice(row)
            },
            calcPriceService(row) {
                return getServicePrice(row, this.pinfo)
            },
            calcSupplierPrice(row){
                return calcSupplierPrice(row, this.pinfo)
            },
            calcBrandPrice(row){
                return calcBrandPrice(row, this.pinfo)
            },
            calcSubFactoryPrice(row){
                return calcSubFactoryPrice(row, this.pinfo)
            },
            calcFujiePrice(row){
                return calcFujiePrice(row, this.pinfo, this.vinInfo)
            },
            onPageChange() {
                this.doSearch()
            }
		}
	}
</script>
<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
<style lang="scss" scoped>
	.assessment-edit {
		color: #FFF;

		::v-deep.el-input-group__append {
			border: none !important;
			background-color: $main-color !important;
		}

		.search-btn {
			background: $main-color;
			color: #FFF;
			padding: 5px 15px;
			i{
				margin: 0 5px 0 0;
			}
		}

		.header {
			h4 {
				margin: 0 0 10px 0;
			}

			.content {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.w25 {
					width: 25%;
				}

				.w33 {
					width: 33.3%;
				}

				.w100 {
					width: 100%;
				}

				.item {
					margin: 0 0 10px 0;

					span {
						display: inline-block;
						margin: 0 10px 0 0;
						border: 1px solid rgba($color: $main-color, $alpha: 0.5);
						padding: 2px 5px;
						background: rgba($color: $main-color, $alpha: 0.2);
						border-radius: 3px;
					}
				}
			}
		}

		.dialog-footer {
			text-align: right;
			margin: 20px 0 0 0;
		}
	}
</style>
