<template>
	<div class="assessment-edit">
		<div class="header">
			<div class="content">
				<div class="item w33"><span>零件号：</span>{{currentRow.productCode}}</div>
				<div class="item w33"><span>零件号名称：</span>{{currentRow.productName}}</div>
				<div class="item w33"><span>发动机号：</span>{{engInfo.engCode}}</div>
				<div class="item w33"><span>功率：</span>{{engInfo.power}}</div>
				<div class="item w33"><span>系列：</span>{{engInfo.series}}</div>
				<div class="item w33"><span>型号：</span>{{engInfo.model}}</div>
				<div class="item w33">
					<el-input placeholder="请输入零件号" v-model="query.keyword" size="small">
						<template slot="append">
							<el-button type="primary" class="search-btn" @click="searchClick"><i
									class="el-icon-search"></i>搜索</el-button>
						</template>
					</el-input>
				</div>
			</div>
		</div>
		<el-table :data="tableData.slice((query.page - 1) * query.pagesize, query.page * query.pagesize)" class="main-table"
			header-row-class-name="table-header" cell-class-name="table-hover" highlight-current-row
			@current-change="handleCurrentChange" default-expand-all :tree-props="{children: 'children'}">
			<el-table-column label="序号" type="index" :index="indexMethod" width="50" align="center">
			</el-table-column>
			<el-table-column label="零件号" prop="partCode" align="center">
			</el-table-column>
			<el-table-column label="名称" prop="partName" align="center">
			</el-table-column>
			<el-table-column label="4S价格" prop="shopPrice" align="center" width="110">
				<template scope="{row}">
					{{row.price}}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150" align="center" class-name="table-operate">
				<template slot-scope="{row}">
					<el-button class="operation-btn" size="small" type="primary" @click="obtainPrice(row)">获取价格</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" @current-change="onPageChange" :current-page="query.page" :total="tableData.length" :page-size="query.pagesize"></el-pagination>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleCancel">取消</el-button>
			<el-button type="primary" @click="handleSubmit">提交</el-button>
		</div>
	</div>
</template>

<script>
	import {
		search
	} from '@/api/insuranceQuote.js'
	export default {
		computed: {
			search() {
				return search
			}
		},
		props: ['engInfo', 'currentRow'],
		watch: {
            engInfo: {
                handler: function (val) {
                    this.tableData = val.partList
                    this.query.keyword = ''
                    this.query.page = 1
                    this.doSearch()
                },
                deep: true,
                immediate: true
            }
		},
		data() {
			return {
				searchInput: '',
				tableData: [],
				query: {
					keyword: '',
					page: 1,
					pagesize: 8,
				},
				selectedRow: {},
				total: 0,
			}
		},
		methods: {
			onPageChange(val) {
				this.query.page = val;
			},
			obtainPrice(row) {
				// row.price = 1000
                console.log(row.price)
			},
			handleCancel() {
				this.$emit('mateCancel')
			},
			handleSubmit() {
				this.$emit('mateSubmit', this.selectedRow)
			},
			handleCurrentChange(val) {
				this.selectedRow = val;
			},
			editClick(row) {
				console.log(row)
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			searchClick() {
				this.query.page = 1
				this.doSearch()
			},
			doSearch() {
                this.query.page = 1
                let list = this.engInfo.partList.filter(e => {
                    return e.partCode.toLowerCase().indexOf(this.query.keyword.toLowerCase()) > -1
                        || e.partName.toLowerCase().indexOf(this.query.keyword.toLowerCase()) > -1
				})
                //按价格从大到小排序
                list.sort((a, b) => {
                    return b.price - a.price
                })
                this.tableData = list
			}
		}
	}
</script>
<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
<style lang="scss" scoped>
	.assessment-edit {
		color: #FFF;

		::v-deep.el-input-group__append {
			border: none !important;
			background-color: $main-color !important;
		}

		.search-btn {
			background: $main-color;
			color: #FFF;
			padding: 5px 15px;

			i {
				margin: 0 5px 0 0;
			}
		}

		.header {
			h4 {
				margin: 0 0 10px 0;
			}

			.content {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.w33 {
					width: 33.3%;
				}

				.w100 {
					width: 100%;
				}

				.item {
					margin: 0 0 10px 0;

					span {
						display: inline-block;
						margin: 0 10px 0 0;
						border: 1px solid rgba($color: $main-color, $alpha: 0.5);
						padding: 2px 5px;
						background: rgba($color: $main-color, $alpha: 0.2);
						border-radius: 3px;
					}
				}
			}
		}

		.dialog-footer {
			text-align: right;
			margin: 20px 0 0 0;
		}
	}
</style>
