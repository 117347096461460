import { render, staticRenderFns } from "./assessmentWcMate.vue?vue&type=template&id=0327035b&scoped=true&"
import script from "./assessmentWcMate.vue?vue&type=script&lang=js&"
export * from "./assessmentWcMate.vue?vue&type=script&lang=js&"
import style0 from "./assessmentWcMate.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./assessmentWcMate.vue?vue&type=style&index=1&id=0327035b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0327035b",
  null
  
)

export default component.exports