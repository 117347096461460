import request from '@/utils/request'

export function fetchVin(query) {
    return request({
        url: '/admin/vin',
        method: 'get',
        params: query
    })
}

export function fetchPinfo(query) {
    return request({
        url: '/admin/pinfo',
        method: 'get',
        params: query
    })
}
