<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>保险定损</el-breadcrumb-item>
				</el-breadcrumb>
				<el-button class="main-btn" size="medium" type="primary" @click="addClick" style="width: 80px;">新增</el-button>
			</div>

			<el-table :data="tableData" class="main-table" header-row-class-name="table-header" cell-class-name="table-hover"
				:row-class-name="tableRowClassName">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
                <el-table-column label="taskId" prop="taskId" align="center">
                </el-table-column>
				<el-table-column label="VIN" prop="vin" align="center">
				</el-table-column>
				<el-table-column label="配件数" prop="itemCount" align="center">
				</el-table-column>
                <el-table-column label="状态" prop="status" align="center">
                    <template slot-scope="{row}">
                        <span v-if="row.status == 'UNEDIT'">未编辑</span>
                        <span v-if="row.status == 'EDITED'">已编辑</span>
                        <span v-if="row.status == 'SENDED'">已发送</span>
                    </template>
                </el-table-column>
				<el-table-column label="时间" prop="addTime" align="center">
				</el-table-column>
				<el-table-column label="操作" width="300" align="center" class-name="table-operate">
					<template slot-scope="{row}">
                        <div v-if="row.preprocess">
						<el-button type="primary" size="small" @click="lockClick(row)" v-if="!row.lock">锁定</el-button>
						<el-button type="info" size="small" @click="unLockClick(row)" v-if="row.lock && row.lockUserId == userId">解锁</el-button>
                            <el-button type="info" size="small" v-if="row.lock && row.lockUserId != userId">已锁定</el-button>
						<el-button v-if="row.lock && row.lockUserId == userId" class="operation-btn" size="small"
                                   type="primary" @click="editClick(row)">编辑</el-button>
						<el-button v-if="row.lock && row.lockUserId == userId && row.taskId > 0"
                                   @click="sendClick(row)"
                                   type="primary" size="small">回传</el-button>
                            <el-button v-if="row.taskId == 0" class="operation-btn" size="small"
                                       type="primary" @click="delClick(row)">删除</el-button>
                        </div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
		<el-dialog title="编辑" :visible.sync="editDialog" width="85%" custom-class="main-dialog edit-dialog" top='10vh'>
			<assessmentEdit
                :quotation="currentRow"
                :pinfo="pinfo"
                :vinInfo="vinInfo"
                :engInfo="engInfo"
                @editCancel="editCancel" @editSubmit="editSubmit"></assessmentEdit>
		</el-dialog>
        <el-dialog title="新增" :visible.sync="addDialog" custom-class="main-dialog edit-dialog">
            <el-form ref="form" label-width="80px">
                <el-form-item label="VIN号">
                    <el-input v-model="vin"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
	</div>
</template>

<script>
import {
    fetchList, lock, unLock, fetchItemList, send, add, del
} from "../../api/insuranceQuote.js";
    import {fetchPinfo, fetchVin} from "../../api/pinfo";
    import assessmentEdit from "./assessmentEdit.vue";
    import {fetchEngInfo} from "../../api/wc";

	export default {
		components: {
			assessmentEdit
		},
		data() {
			return {
				editDialog: false,
				claimBtnText: '认领',
				claimBtnType: 'primary',
				tableData: [],
				query: {
					keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: '',
                userId: '',
                currentRow: {},
                pinfo: {},
                engInfo: {},
                vin: '',
                vinInfo: {},
                addDialog: false,
			};
		},
		created() {
            if(window.Notification) {
                if(Notification.permission != 'granted') {
                    Notification.requestPermission((permission) => {
                        console.log(permission)
                    })
                }
            }

            this.getList();
            //定时刷新
            setInterval(() => {
                let total = this.total
                Promise.all([this.getList()]).then(() => {
                    if(total < this.total){
                        if(Notification.permission == 'granted') {
                            let notification = new Notification('有新的定损单，请及时处理')
                            notification.onclick = () => {
                                this.$nextTick(() => {
                                })
                            }
                        }
                        this.$message({
                            message: '有新的定损单，请及时处理',
                            type: 'success',
                            showClose: true,
                            duration: 0
                        })
                    }
                })
            }, 1000*60)
            let userId = localStorage.getItem('userId')
            if(userId) {
                this.userId = userId
            }
		},
		methods: {
			editSubmit() {
                this.getList()
			},
			editCancel() {
				this.editDialog = false
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                return new Promise((resolve) => {
                    fetchList(this.query).then(res => {
                        this.tableData = res.data.content
                        this.total = res.data.totalElements
                        resolve()
                    })
                })
			},
			editClick(row) {
                this.currentRow = row
                Promise.all([this.getDetail(row.id), this.getPinfo(row.pid)]).then((res) => {
                    this.engInfo = {
                        partList: []
                    }
                    this.getVinInfo(row.vin)
                    console.log('price', res.length)
                    this.editDialog = true
                })
			},
			lockClick(row) {
				this.$confirm("确定要锁定吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
                        lock({
                            id: row.id
                        }).then(() => {
                            this.getList()
                            this.$message({
                                type: "success",
                                message: "锁定成功!",
                            });
                        })
					})
			},
            unLockClick(row) {
                this.$confirm("确定要解锁吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        unLock({
                            id: row.id
                        }).then(() => {
                            this.getList()
                            this.$message({
                                type: "success",
                                message: "解锁成功!",
                            });
                        })
                    })
            },
			handleSort(e) {
				this.query.sortProp = e.prop
				this.query.sortOrder = e.order
				this.query.page = 1
				this.getList()
			},
            getPinfo(pid) {
                return new Promise((resolve, reject) => {
                    fetchPinfo({ id: pid }).then(res => {
                        if (res.code == 0) {
                            this.pinfo = res.data;
                            resolve()
                        }else{
                            reject()
                        }
                    })
                })
            },
            getDetail(id) {
                return new Promise((resolve, reject) => {
                    fetchItemList({ id: id }).then(res => {
                        if (res.code == 0) {
                            this.currentRow.quotationItemList = res.data;
                            resolve()
                        }else{
                            reject()
                        }
                    })
                })
            },
            sendClick(row){
                this.$confirm("确定要回传吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        send({
                            id: row.id
                        }).then(() => {
                            this.getList()
                            this.$message({
                                type: "success",
                                message: "回传成功!",
                            });
                        })
                    })
            },
            getVinInfo(vin) {
                if(!this.pinfo.engineTypeText || this.pinfo.engineTypeText.indexOf('潍柴') == -1) {
                    return
                }
                fetchVin({ condition: vin }).then(res => {
                    this.vinInfo = res.data;
                    let engCode = this.vinInfo.engine
                    this.getWcEngInfo(engCode)
                })
            },
            getWcEngInfo(enginCode) {
                fetchEngInfo({ engCode: enginCode }).then(res => {
                    this.engInfo = res.data;
                    console.log(this.engInfo)
                })
            },
            addClick() {
                this.vin = ''
                this.addDialog = true
            },
            addSubmit() {
                add({
                    vin: this.vin,
                }).then(() => {
                    this.$message({
                        type: "success",
                        message: "添加成功!",
                    });
                    this.addDialog = false
                    this.getList()
                })
            },
            delClick(row) {
                this.$confirm("确定要删除吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        del({
                            id: row.id
                        }).then(() => {
                            this.getList()
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        })
                    })

            }
        },
	};
</script>

<style lang="scss">
	.edit-dialog {
		.el-dialog__body {
			padding: 10px 20px 30px 20px !important;
			max-height: 70vh;
			overflow-y: auto;
		}
	}
</style>
