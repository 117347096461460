import request from '@/utils/request'
import axios from "axios";

export function fetchList(query) {
    return request({
        url: '/admin/insuranceQuote/list',
        method: 'get',
        params: query
    })
}

export function fetchItemList(query) {
    return request({
        url: '/admin/insuranceQuote/itemList',
        method: 'get',
        params: query
    })
}

export function fetchCondidateList(query) {
    return request({
        url: '/admin/insuranceQuote/defaultCondidate',
        method: 'get',
        params: query
    })
}

export function search(query) {
    return request({
        url: '/admin/insuranceQuote/search',
        method: 'get',
        params: query
    })
}

export function lock(data) {
    return request({
        url: '/admin/insuranceQuote/lock',
        method: 'post',
        data
    })
}

export function unLock(data) {
    return request({
        url: '/admin/insuranceQuote/unLock',
        method: 'post',
        data
    })
}

export function updateItems(data) {
    return request({
        url: '/admin/insuranceQuote/updateItems',
        method: 'post',
        data
    })
}

export function preview(query) {
    return request({
        url: '/admin/insuranceQuote/preview',
        method: 'get',
        params: query
    })
}

export function send(data) {
    return request({
        url: '/admin/insuranceQuote/send',
        method: 'post',
        data
    })
}

export function downFile(url,parameter){
    return axios({
        url: url,
        params: parameter,
        method:'get' ,
        responseType: 'blob'
    })
}

export function add(data) {
    return request({
        url: '/admin/insuranceQuote/add',
        method: 'post',
        data
    })
}

export function del(data) {
    return request({
        url: '/admin/insuranceQuote/delete',
        method: 'post',
        data
    })
}

export function getNowFormatStr() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    let strHours = date.getHours();
    let strMinutes = date.getMinutes();
    let strSeconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (strHours >= 0 && strHours <= 9) {
        strHours = "0" + strHours;
    }
    if (strMinutes >= 0 && strMinutes <= 9) {
        strMinutes = "0" + strMinutes;
    }
    if (strSeconds >= 0 && strSeconds <= 9) {
        strSeconds = "0" + strSeconds;
    }
    return month +''+ strDate +''+ strHours +''+ strMinutes +''+ strSeconds;
}
