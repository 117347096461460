var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assessment-edit"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"item w25"},[_c('span',[_vm._v("零件号：")]),_vm._v(_vm._s(_vm.currentRow.productCode))]),_c('div',{staticClass:"item w25"},[_c('span',[_vm._v("零件号名称：")]),_vm._v(_vm._s(_vm.currentRow.productName))]),_c('div',{staticClass:"item w25"},[_c('span',[_vm._v("修理厂报价：")]),_vm._v(_vm._s(_vm.currentRow.repairPrice))]),_c('div',{staticClass:"item w25"},[_c('el-input',{attrs:{"placeholder":"请输入零件号","size":"small"},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}},[_c('template',{slot:"append"},[_c('el-button',{staticClass:"search-btn",attrs:{"type":"primary"},on:{"click":_vm.searchClick}},[_c('i',{staticClass:"el-icon-search"}),_vm._v("搜索")])],1)],2)],1)])]),_c('el-table',{staticClass:"main-table",attrs:{"data":_vm.tableData,"header-row-class-name":"table-header","cell-class-name":"table-hover","highlight-current-row":"","default-expand-all":"","tree-props":{children: 'children'}},on:{"current-change":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","index":_vm.indexMethod,"width":"50","align":"center"}}),_c('el-table-column',{attrs:{"label":"零件号","prop":"productCode","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.replacePartNo ? row.replacePartNo : row.productCode)+" "),(row.disuse && row.disuse == 1)?_c('el-tag',{attrs:{"type":"danger","size":"mini","effect":"dark"}},[_vm._v(" 停采 ")]):_vm._e(),(_vm.pinfo && _vm.pinfo.productCode && row.productCode.substr(-3, 1) == '-'
                        && row.productCode.substr(-2, 2) == _vm.pinfo.productCode.substr(-2, 2))?_c('el-tooltip',{attrs:{"content":"与原车颜色匹配","placement":"top"}},[_c('el-button',{staticClass:"color-btn",attrs:{"type":"primary","icon":"el-icon-connection","circle":"","size":"mini"}})],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"名称","prop":"productName","align":"center"}}),_c('el-table-column',{attrs:{"label":"4S价格","prop":"shopPrice","align":"center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(_vm.calcPrice(row))?_c('span',[_vm._v(_vm._s(_vm.calcPrice(row)))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"赋界价格","prop":"fujiePrice","align":"center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(_vm.calcFujiePrice(row))?_c('span',[_vm._v(_vm._s(_vm.calcFujiePrice(row)))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"市场原厂价","prop":"factoryPrice","align":"center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(_vm.calcSupplierPrice(row))?_c('span',[_vm._v(_vm._s(_vm.calcSupplierPrice(row)))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"市场品牌价","prop":"brandPrice","align":"center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(_vm.calcBrandPrice(row))?_c('span',[_vm._v(_vm._s(_vm.calcBrandPrice(row)))]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.query.page,"page-size":_vm.query.pagesize},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.onPageChange}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }